// TermsPage.js
import TermsNavbar from "./TermsNavBar";
import { useEffect } from "react";

function TermsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="bg-white text-gray-800">
      <TermsNavbar />
      <main className="container mx-auto p-8 sm:px-14 md:px-28">
        <h1 className="text-5xl font-bold text-center text-gray-900 mb-14">
          Terms and Conditions
        </h1>

        <section className="space-y-14">
          <div className="prose lg:prose-2xl mx-auto text-base text-gray-700">
            {/* Introduction */}
            <div className="mb-14">
              <h2 className="text-4xl font-bold text-gray-900 border-b pb-3 mb-6">
                Introduction
              </h2>
              <p>
                Welcome to HostelHubb Ghana. By downloading, installing,
                accessing, or using the App, you agree to be bound by these
                Terms and Conditions. Please read them carefully.
              </p>
            </div>

            {/* Definitions */}
            <div className="mb-14">
              <h2 className="text-4xl font-bold text-gray-900 border-b pb-3 mb-6">
                1. Definitions
              </h2>
              <ul className="list-inside list-disc space-y-3 text-gray-600">
                <li>
                  <strong>App:</strong> The HostelHubb mobile application, where
                  users can browse, book, and manage hostel reservations.
                </li>
                <li>
                  <strong>User:</strong> The individual using the HostelHubb App
                  to make bookings, inquire about hostels, and manage their
                  reservations.
                </li>
                <li>
                  <strong>Hostel:</strong> The accommodation provider listed on
                  HostelHubb's platform, offering short-term or long-term
                  lodging for users.
                </li>
                <li>
                  <strong>Booking:</strong> The confirmed reservation made by
                  the User through the App for a specified period at a hostel.
                </li>
                <li>
                  <strong>Hostel Provider:</strong> The individual or entity
                  that manages or owns the hostel accommodations available for
                  booking on HostelHubb.
                </li>
                <li>
                  <strong>Booking Service:</strong> The online reservation,
                  order, facilitated payment, or other service provided by
                  HostelHubb to enable the booking of hostel accommodations.
                </li>
                <li>
                  <strong>Booking Reservation:</strong> The confirmed order,
                  purchase, payment, and booking of hostel accommodations
                  through the App.
                </li>
              </ul>
            </div>

            {/* Eligibility */}
            <div className="mb-14">
              <h2 className="text-4xl font-bold text-gray-900 border-b pb-3 mb-6">
                2. Eligibility
              </h2>
              <ul className="list-inside list-disc space-y-3 text-gray-600">
                <li>You must be at least 13 years old to use the HostelHubb App.</li>
                <li>
                  You must have the legal authority to enter into a binding
                  agreement as outlined in these Terms.
                </li>
                <li>
                  All information you provide when using the App must be
                  accurate and complete to ensure proper booking and
                  communication.
                </li>
              </ul>
            </div>

            {/* User Account */}
            <div className="mb-14">
              <h2 className="text-4xl font-bold text-gray-900 border-b pb-3 mb-6">
                3. User Account
              </h2>
              <ul className="list-inside list-disc space-y-3 text-gray-600">
                <li>
                  <strong>Account Creation:</strong> To fully access the
                  features of the HostelHubb App, you must create a User
                  account. This includes providing accurate and complete
                  information during the registration process. By creating an
                  account, you acknowledge that you are providing personal
                  information that will be processed in accordance with our
                  Privacy Policy and relevant Ghanaian data protection laws.
                </li>
                <li>
                  <strong>Confidentiality of Credentials:</strong> It is your
                  responsibility to maintain the confidentiality and security of
                  your account credentials, including your username and
                  password. HostelHubb shall not be held liable for any loss or
                  damage arising from your failure to secure your account
                  information.
                </li>
                <li>
                  <strong>Account Responsibility:</strong> As the sole
                  authorized user of your account, you agree to use the App in
                  accordance with these Terms and applicable Ghanaian laws. You
                  are responsible for all activities that occur under your
                  account.
                </li>
                <li>
                  <strong>Compliance with Local Laws:</strong> By creating an
                  account, you confirm that you are using the HostelHubb
                  platform for lawful purposes.
                </li>
                <li>
                  <strong>Account Termination:</strong> HostelHubb reserves the
                  right to terminate or suspend your account at any time if we
                  believe that you have violated any of these Terms.
                </li>
              </ul>
            </div>

            {/* Bookings */}
            <div className="mb-14">
              <h2 className="text-4xl font-bold text-gray-900 border-b pb-3 mb-6">
                4. Bookings
              </h2>
              <ul className="list-inside list-disc space-y-3 text-gray-600">
                <li>
                  <strong>Platform Facilitation Only:</strong>HostelHubb serves solely as an
                   online platform to connect users with hostels. By using the HostelHubb App, 
                   users acknowledge that HostelHubb is not a party to any agreement between
                    the user and the hostel. HostelHubb provides an interface for browsing,
                     booking, and paying for accommodations, but does not own, operate, or
                      manage any hostels listed on the platform. The responsibility for
                       the actual booking and transaction lies entirely with the hostel
                        management, not with HostelHubb.
                </li>
                <li>
                  <strong>Booking Confirmation and Payment:</strong> Users agree to pay for their
                   bookings in full at the time of reservation or as per the payment terms set 
                   by the hostel. All bookings are subject to the availability of the accommodation
                    and final confirmation from the hostel. HostelHubb does not guarantee the 
                    availability or suitability of any hostel and will not be held liable if a
                     booking cannot be fulfilled. Users are advised to confirm their bookings 
                     directly with the hostel for further assurance.
                </li>
                <li>
                  <strong>Refund and Cancellation Policies:</strong> HostelHubb does not manage, 
                  process, or enforce refund or cancellation policies for any bookings made
                   through the platform. Each hostel is responsible for establishing and 
                   communicating its own policies on refunds, cancellations, or rebookings.
                    Any disputes, claims, or concerns regarding refunds or cancellations 
                    should be addressed directly with the hostel. HostelHubb disclaims 
                    any liability in situations where the hostel is unable or unwilling 
                    to offer a refund, as we merely facilitate the booking on behalf of the user.
                </li>
                <li>
                  <strong>Disputes and Resolution:</strong> In the event of any
                  dispute or issue related to a booking, the user must resolve
                  these matters directly with the hostel management.
                </li>
                <li>
                  <strong>Exclusion of Liability:</strong> HostelHubb disclaims all 
                  liability for any outcomes arising from bookings made through the 
                  platform. By using the HostelHubb App, users acknowledge that they 
                  assume full responsibility for their booking choices, including the 
                  suitability, quality, and availability of the selected accommodation.
                   HostelHubb shall not be held accountable for any direct, indirect, 
                   incidental, or consequential losses, damages, or claims arising from 
                   or related to bookings, payments, cancellations, or refunds. This
                    exclusion of liability applies to any legal jurisdiction in which 
                    HostelHubb operates, in alignment with Ghanaian law.
                </li>
              </ul>
            </div>


            {/* Our Platform */}
<div className="mb-12">
  <h2 className="text-3xl font-bold text-gray-900 border-b pb-2 mb-4">
    5. Our Platform
  </h2>
  <ul className="list-inside list-disc space-y-2 text-gray-600">
    <li>
      <strong>Platform Role:</strong> HostelHubb serves as a digital platform 
      designed to facilitate the hostel booking process, offering users the 
      convenience of browsing available accommodations and booking their stay 
      directly through the app. However, HostelHubb does not directly manage,
       own, or operate any hostel listed on the platform. We merely act as an
        intermediary to connect users with accommodation providers.
    </li>
    <li>
      <strong>Information Accuracy:</strong> HostelHubb strives to provide 
      accurate, complete, and up-to-date information regarding hostel listings,
       amenities, pricing, and availability. Despite our efforts, we cannot 
       guarantee the absolute accuracy or completeness of all content displayed 
       on the platform, as this information is provided by the hostels. Users 
       are advised to verify essential details with the hostel management directly
        prior to booking.
    </li>
    <li>
      <strong>Content Variability:</strong> The content, features, and design of
       the HostelHubb platform may vary depending on user location, preferences,
        and platform updates. We may periodically introduce new features, modify 
        existing ones, or customize the user experience based on various factors,
         including device type and regional settings, to improve overall service 
         and user satisfaction.
    </li>
    <li>
      <strong>No Endorsement:</strong> HostelHubb does not endorse, recommend, or 
      guarantee any specific hostel or accommodation listed on the platform. All 
      accommodation providers listed are independent entities, and users are 
      encouraged to exercise discretion and make informed decisions based on personal 
      research and reviews.
    </li>
    <li>
      <strong>Contractual Relationship:</strong> When you book a hostel through the 
      HostelHubb platform, your contractual relationship exists solely between you 
      (the user) and the hostel management. HostelHubb is not a party to any agreement 
      or contract between the user and the accommodation provider. Therefore, any issues 
      or disputes arising from your booking must be resolved directly with the hostel.
    </li>
    <li>
      <strong>Platform Availability:</strong> HostelHubb aims to offer a consistent,
       uninterrupted booking experience, available 24/7. However, the platform may
        occasionally experience downtime for scheduled maintenance, updates, or 
        unforeseen technical issues. During these times, some services may be temporarily
         unavailable, and we appreciate your understanding as we work to restore full functionality promptly.
    </li>
    <li>
      <strong>Limitation of Responsibility:</strong> While HostelHubb aims to ensure a 
      safe and reliable platform for hostel bookings, we shall not be held liable for 
      any incidents, losses, or damages resulting from transactions, interactions, or 
      contracts made between users and hostels. The user acknowledges that HostelHubb's
       role is limited to facilitating connections, and assumes full responsibility for
        their engagement with listed hostels.
    </li>
  </ul>
</div>

{/* Intellectual Property */}
<div className="mb-12">
  <h2 className="text-3xl font-bold text-gray-900 border-b pb-2 mb-4">
    6. Intellectual Property
  </h2>
  <ul className="list-inside list-disc space-y-2 text-gray-600">
    <li>
      <strong>Ownership of App and Content:</strong> The HostelHubb platform, 
      including the mobile application (the "App") and all its associated content,
       features, and intellectual property, are the exclusive property of 
       <span className="font-semibold"> NAG HOSTELHUBB</span> and are protected by
        international copyright, trademark, and other intellectual property laws.
         All content within the App, including but not limited to text, images, 
         graphics, logos, icons, software, and other materials, is owned by 
         <span className="font-semibold"> NAG HOSTELHUBB</span> or its licensors except for plugin illustrations.
    </li>
    <li>
      <strong>Unauthorized Use Prohibited:</strong> You are strictly prohibited from copying,
       reproducing, distributing, displaying, modifying, or creating derivative works based
        on any part of the App or its content without express written permission from 
        <span className="font-semibold"> NAG HOSTELHUBB</span>. Any unauthorized use of the 
        content or intellectual property found on the platform is a violation of 
        <span className="font-semibold"> NAG HOSTELHUBB's</span> rights and may result 
        in legal consequences.
    </li>
    <li>
      <strong>Prohibited Actions:</strong> You are prohibited from downloading, extracting,
       or using content from the App for purposes unrelated to the intended use of the platform.
        This includes, but is not limited to, any attempt to "scrape," "clone," or otherwise 
        duplicate any content for commercial, personal, or unauthorized purposes. Additionally, 
        you may not reverse-engineer, decompile, or otherwise attempt to derive the source code 
        of the App, unless expressly permitted by law.
    </li>
    <li>
      <strong>Strict Penalties for Duplication:</strong> Any attempt to reproduce, modify,
       or distribute any content from the App without permission from 
       <span className="font-semibold"> NAG HOSTELHUBB</span> will result in 
       severe legal actions, including but not limited to:
      <ul className="list-inside list-disc text-gray-600">
        <li>Immediate suspension or termination of your account on the platform.</li>
        <li>Legal action for copyright infringement and violation of intellectual property laws.</li>
        <li>Financial restitution, including damages for any financial losses or harm caused 
          by the unauthorized use of our intellectual property.</li>
        <li>Potential criminal prosecution, where applicable under intellectual property laws.</li>
      </ul>
    </li>
    <li>
      <strong>License Grant:</strong> By using the App, 
      <span className="font-semibold"> NAG HOSTELHUBB</span> grants you a limited, 
      non-exclusive, non-transferable license to access and use the content for your personal,
       non-commercial use, provided that you comply with all terms of this Agreement. You do
        not have the right to sell, distribute, or otherwise exploit any content for commercial
         gain without obtaining prior written consent from <span className="font-semibold"> NAG HOSTELHUBB</span>.
    </li>
    <li>
      <strong>Monitoring and Enforcement:</strong> <span className="font-semibold"> NAG HOSTELHUBB</span> 
      reserves the right to monitor the use of the platform and to take appropriate measures to detect 
      and prevent any unauthorized duplication or infringement of our intellectual property. We employ 
      advanced technologies and legal measures to track and prevent unauthorized copying or redistribution 
      of content and will take swift action against violators.
    </li>
    <li>
      <strong>Respect for Intellectual Property:</strong> We ask that users respect the intellectual 
      property rights of <span className="font-semibold"> NAG HOSTELHUBB</span> and all third parties. 
      Any violation of these intellectual property rights undermines the integrity of the platform and 
      may damage the user experience for everyone. We are committed to protecting our intellectual 
      property, and we encourage users to report any suspected violations by contacting our legal team.
    </li>
  </ul>
</div>

{/* Changes */}
<div className="mb-12">
  <h2 className="text-3xl font-bold text-gray-900 border-b pb-2 mb-4">
    7. Changes to the Terms of Service
  </h2>
  <ul className="list-inside list-disc space-y-2 text-gray-600">
    <li>
      <strong>Right to Modify:</strong> <span className="font-semibold">NAG HOSTELHUBB</span>
       reserves the right, at its sole discretion, to modify, amend, or update 
       these Terms of Service at any time. Such changes may occur to reflect changes in
        the law, technological advancements, or to improve the services offered by the platform.
    </li>
    <li>
      <strong>Notification of Changes:</strong> We will make reasonable efforts to notify
       users of material changes to these Terms. However, it is your responsibility to
        regularly review these Terms to ensure you are aware of any updates or modifications.
    </li>
    <li>
      <strong>Acceptance of Changes:</strong> Your continued use of the App following the 
      posting of changes to these Terms constitutes your acceptance of those changes. If 
      you do not agree with the updated Terms, you must cease using the App immediately. 
      Continuing use of the platform after changes signifies your understanding and acceptance of the revised Terms.
    </li>
    <li>
      <strong>Binding Nature of Updates:</strong> Any changes made to these Terms will 
      be binding on all users, whether or not they are notified, and will apply to all 
      future interactions with the App. It is therefore critical to periodically review 
      the Terms to ensure you are in compliance with the most current version.
    </li>
  </ul>
</div>

{/* Contact */}
<div className="mb-12">
  <h2 className="text-3xl font-bold text-gray-900 border-b pb-2 mb-4">
    8. Contact Information
  </h2>
  <ul className="list-inside list-disc space-y-2 text-gray-600">
    <li>
      <strong>Company Registration:</strong> The business is formally registered
       under the name <span className="font-semibold">NAG HOSTELHUBB</span>. 
       For any inquiries regarding these Terms, intellectual property, or other 
       aspects of the platform, you may contact us via the details provided below.
    </li>
    <li>
      <strong>Contact Us:</strong> If you have any questions, concerns, or feedback
       regarding these Terms of Service, our intellectual property, or any other aspect 
       of your experience with the App, we encourage you to reach out to us. We are committed 
       to addressing any issues promptly and providing you with the necessary assistance.
    </li>
    <li>
      <strong>Response Time:</strong> We aim to respond to all inquiries within a reasonable 
      time frame, typically within 2-3 business days. However, in cases of high volume or 
      complex issues, response times may vary. We appreciate your patience in such instances.
    </li>

    <li>
      <strong>Account-Related Inquiries:</strong> If you need assistance with account management,
       technical support, or account-related issues, please use the dedicated account support 
       page on the platform
    </li>
  </ul>
</div>

            <p className="italic text-center mt-14 text-lg text-gray-600">
            <li>
      <strong>Compliance with Laws:</strong> By using the HostelHubb platform, you 
      agree to adhere to all applicable laws, regulations, and rules relevant to your 
      use of the platform. This includes but is not limited to laws governing online 
      transactions, privacy, and data protection as outlined by the Republic of Ghana. 
      Users are responsible for ensuring that their actions on the platform are lawful 
      and do not infringe on the rights of others.
    </li>
            </p>
          </div>

        </section>
      </main>
    </div>
  );
}

export default TermsPage;